.testimonials {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
}
.left-t {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 2rem;
  text-transform: uppercase;
  color: white;
}
.left-t > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
}
.left-t > :nth-child(2),
.left-t > :nth-child(3) {
  font-weight: bold;
  font-size: 3rem;
}
.left-t > :nth-child(4) {
  text-transform: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
}

/* right testimonials */
.right-t {
  flex: 1 1;
  position: relative;
}
.right-t > img {
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  position: absolute;
  right: 8rem;
  top: 2rem;
}
.right-t > :nth-child(1) {
  position: absolute;
  width: 17rem;
  height: 20rem;
  border: 2px solid orange;
  background-color: transparent;
  right: 9rem;
  top: 0.9rem;
}
.right-t > :nth-child(2) {
  background: var(--planCard);
  position: absolute;
  width: 17rem;
  height: 19rem;
  top: 4rem;
  right: 7rem;
}
.arrow {
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 3rem;
}
.arrow > img {
  width: 1.5rem;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .testimonials {
    flex-direction: column;
    margin-top: -3rem;
  }
  .left-t {
    & :nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
    }
    & :nth-child(2){
      font-size: xx-large;
    }
    & :nth-child(3){
      font-size: xx-large;
      margin-top: -20px;
    }
    & :nth-child(4){
      word-spacing: 0px;
      line-height: 1.7rem;
      margin-top: -20px;
    }
  }
  .right-t{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    & >div{
      position: relative;
      display: none;
    }
    & img{
      position: relative;
      top: 0;
      right: 0;
      align-self: center;
    }
    & :last-child{
      display: flex;
      bottom: 0;
      left: 0px;
    }
  }


}
