.reasons {
  padding: 0 2rem;
  display: flex;
  gap: 2rem;
}
.left-r {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}
.left-r > img {
  object-fit: cover;
}
.left-r > :nth-child(1) {
  width: 12rem;
  height: 28rem;
  grid-row: 1/3;
}
.left-r > :nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/4;
}
.left-r > :nth-child(3) {
  width: 14rem;
  grid-column: 2/3;
  grid-row: 2;
}
.left-r > :nth-child(4) {
  width: 10rem;
  height: 11.2rem;
  grid-column: 3/4;
  grid-row: 2;
}
.right-r {
  flex: 1 1;
  display: flex;
  gap: 1rem;
  text-transform: uppercase;
  flex-direction: column;
}
.right-r > span {
  color: var(--orange);
  font-weight: bold;
}
.right-r > div {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}
.details-r {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.details-r > div {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  gap: 1rem;
}
.details-r > div > img {
  width: 2rem;
  height: 2rem;
}
.partners {
  display: flex;
  gap: 1rem;
}
.partners > img {
  width: 2.5rem;
}
@media (max-width: 768px) {
  .reasons {
    flex-direction: column;
  }
  .left-r {
    grid-auto-rows: auto;
    overflow: hidden;
    margin-top: -2.5rem;
  }
  .left-r > :nth-child(1) {
    width: 7rem;
    height: 17rem;
  }
  .left-r > :nth-child(2) {
    width: 15rem;
    height: 10rem;
  }
  .left-r > :nth-child(3) {
    width: 7rem;
  }
  .left-r > :nth-child(4) {
    width: 7rem;
    height: 6rem;
  }
  .right-r {
    gap: 1rem;
    /* margin-top: 1r; */
    font-size: x-large;

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
    }
    & > div:nth-of-type(1) {
      margin-top: -1.5rem;
      
    }
    & > div > span {
      font-size: x-large;
    }
    & >div:nth-of-type(2)>div{
      /* color: red; */
      font-size: 0.8rem;
      & >img{
        width: 1.3rem;
        height: 1.3rem;
      }
    }
    & .partners {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
}
