.programs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}
.programs-header {
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: space-between;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}
/* program-category */
.programs-category {
  display: flex;
  gap: 1rem;
}
.category {
  background-color: gray;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem;
  transition: all 300ms ease-in-out;
  border-radius: 4px;
}
.category > :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: white;
}
.category > :nth-child(2) {
  font-size: 1rem;
}
.category > :nth-child(3) {
  font-size: 0.9rem;
  line-height: 25px;
}
.join-now {
  display: flex;
  align-items: center;
  gap: 2rem;
  transition: all 0.5s ease-in-out;
  width: fit-content;
  padding: 5px;
}
.join-now > img {
  width: 1rem;
}

.category:hover {
  background: var(--planCard);
  cursor: pointer;
  transform: scale(0.9);
  .join-now {
    animation: joinAnim 0.5s ease-in-out infinite alternate;
    transform: translateX(10px);
  }
}

@keyframes joinAnim {
  0% {
    background: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    border-radius: 5px;
    border: 2px solid white;
  }
  100% {
    background: linear-gradient(210.41deg, #fa5042 1.14%);
    border-radius: 5px;
    border: 2px solid white;
  }
}
@media screen and (max-width: 768px) {
  .programs-header {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .programs-category {
    display: flex;
    flex-direction: column;
  }
  .category:hover{
transform: scale(1.05);
  }
}
