.plans-container {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  gap: 1.5rem;
  padding: 1.5rem;
  width: 15rem;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  transform: scale(1);
}

.default-scale {
  background: var(--planCard);
  transform: scale(1.1);
  &.plan > :nth-child(1) {
    fill: white;
  }
  &.plan > button {
    color: orange;
  }
}

.hovered {
  background: var(--planCard);
  transform: scale(1.1);
  &.plan > :nth-child(1) {
    fill: white;
  }
  &.plan > button {
    color: orange;
  }
}

.plan > svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}

.plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}

.plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}

.plan > :nth-child(5) {
  font-size: 0.8rem;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.feature > img {
  width: 1rem;
}

.plan:hover {
  transform: scale(1.1);
}

/* .plan:not(.hovered):not(.default-scale) {
    transform: scale(1);
  }
   */
.plan-blur-1 {
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 0rem;
}
.plan-blur-2 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0rem;
}
@media screen and (max-width: 768px) {
  .plans {
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
    gap: 2rem;
  }
  .default-scale {
    background: var(--caloryCard);
    color: white;
    transform: scale(1);
    &.plan > :nth-child(1) {
      fill: var(--orange);
    }
    &.plan > button {
      color: black;
    }
  }
  .plan {
    padding: 1rem;
    gap: 1rem;
    width: 13.5rem;
    & > :nth-child(3) {
      font-size: 1rem;
    }
    & .feature {
      gap: 0.7rem;
      font-size: small;
    }
  }
}
